/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

 import 'swiper/swiper-bundle.min.css';
import "./src/css/index.css"
import "./src/css/swiper.css"
 
export const onRouteUpdate = (_) => {
  if (process.env.NODE_ENV === `production` && window.dataLayer) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      const data = window.dataLayer;
      const eventName = `gatsby-route-change`;

      data.push({ event: eventName });
    }, 50)
  }
};
