module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://depalmaworkwear.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DePalma Workwear","short_name":"DePalma","description":"Stylish workwear for professionals and hobbyists. Welcome to our official website. Here, both private individuals and companies shop easily with free shipping and free returns, or find a store near you!","start_url":"/","background_color":"#2C313D","theme_color":"#2C313D","display":"minimal-ui","icon":"src/images/Depalma-Favicon-box_120x120.svg","icons":[{"src":"src/images/Depalma-Favicon-box_120x120.svg","sizes":"128x128","type":"image/png","purpose":"any maskable"},{"src":"src/images/Depalma-Favicon-box_120x120.svg","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"lang":"en","localize":[{"start_url":"/se/sv/","lang":"sv","name":"DePalma Workwear","short_name":"DePalma","description":"Stilrena arbetskläder för proffs och hemmasnickare. Välkommen till vår officiella hemsida. Här handlar både privatpersoner och företag enkelt med fri frakt och fria returer, eller hitta en butik nära dig!"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fd5d5d441728186d1f4a0c690025fedc"},
    },{
      plugin: require('../packages/happy-rabbit-gatsby-sanity-cms/gatsby-browser.js'),
      options: {"plugins":[],"clientConfig":{"projectId":"sbdrmpes","dataset":"production","apiVersion":"2021-03-31","token":"","useCdn":true}},
    },{
      plugin: require('../plugins/gatsby-shopify-buy-plugin/gatsby-browser.js'),
      options: {"plugins":[],"stores":{"store":{"domain":"depalma-workwear.myshopify.com","apiVersion":"2023-04","storefrontAccessToken":"01a5ba8a76e68514468c0b65a3b65fa0"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-NWWWW57","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
