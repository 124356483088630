// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-account-page-js": () => import("./../../../src/templates/accountPage.js" /* webpackChunkName: "component---src-templates-account-page-js" */),
  "component---src-templates-checkout-page-js": () => import("./../../../src/templates/checkoutPage.js" /* webpackChunkName: "component---src-templates-checkout-page-js" */),
  "component---src-templates-orders-page-js": () => import("./../../../src/templates/ordersPage.js" /* webpackChunkName: "component---src-templates-orders-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-category-page-js": () => import("./../../../src/templates/productCategoryPage.js" /* webpackChunkName: "component---src-templates-product-category-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

